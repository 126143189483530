import { useCallback, useEffect, useState } from 'react';

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
	const versionsA = versionA.split(/\./g);

	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());
		// eslint-disable-next-line no-continue
		if (a === b) {
			continue;
		}
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

interface CacheBusterProps {
	currentVersion: string;
	children: any;
	isEnabled: boolean;
}

const CacheBuster = ({ currentVersion, children, isEnabled }: CacheBusterProps) => {
	const [status, setStatus] = useState<any>({
		loading: true,
		isLatestVersion: false,
	});

	const refreshCacheAndReload = useCallback(() => {
		console.info('Clearing cache and hard reloading...');

		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(async (names) => {
				await Promise.all(names.map((name) => caches.delete(name)));
			});
			// delete browser cache and hard reload
			window.location.reload();
		}
	}, []);

	useEffect(() => {
		if (!isEnabled) {
			return;
		}

		fetch(`/meta.json?timestamp=${new Date().getTime()}`)
			.then((response) => response.json())
			.then((meta: any) => {
				const latestVersion = meta.version;
				const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
				// eslint-disable-next-line no-debugger
				debugger;
				if (shouldForceRefresh) {
					setStatus({
						loading: false,
						isLatestVersion: false,
					});
				} else {
					setStatus({
						loading: false,
						isLatestVersion: true,
					});
				}
			});
	}, [isEnabled]);

	return children({
		loading: status.loading,
		isLatestVersion: status.isLatestVersion,
		isEnabled,
		refreshCacheAndReload,
	});
};

export default CacheBuster;
