import React, { useCallback, useRef, useState } from 'react';
import { IconButton, Tab, Tabs } from '@material-ui/core';
import { AccountCircleOutlined, ArrowBack } from '@material-ui/icons';
import { ScaleLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { joinClassNames } from '../../../services/elementHelperService';
import { createPoll, publishPoll, unpublishPoll } from '../../../actions/pollActions';
import { showMessage } from '../../../actions/globalActions';
import { mediaNames, messageTypes } from '../../../constants/mediaConstants';
import {
	setOpenChatSettings,
	setOpenInformationDialog,
	setViewEngagementLoading,
} from '../../../actions/viewEngagementActions';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../../actions/publishActions';
import { getDetails } from '../../../services/mediaDisplayService';

import ChatContainer from './ChatContainer/ChatContainer';
import QuestionAndAnswerContainer from './QuestionAndAnswer/QuestionAndAnswerContainer';
import PollsContainer from './Polls/PollsContainer';
import { PollsContainerTemplate } from './Polls/PollsContainerTemplate';
import { ViewerEngagementTemplate } from './ViewerEngagementTemplate';
import ViewerEngagementAdminProfileSettings from './ViewerEngagementAdminProfileSettings';
import { useFetchSettings } from './useFetchSettings';

import './ViewerEngagement.scss';
import ViewerEngagementSettings from './ViewerEngagementSettings';

const baseClassName = ViewerEngagementTemplate.baseClassName;

const ViewerEngagement: React.FC<ViewerEngagementTemplate.ViewerEngagementProps> = ({
	backToList,
	mediaId,
	mediaDetails,
	reloadData,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useState(ViewerEngagementTemplate.TAB.chat);

	const { defaultAccountId, username } = useSelector((state: any) => state.session);
	const { showCreatePollForm, activePoll } = useSelector((state: any) => state.pollReducer);
	const { openInformationDialog, loading, openChatSettings } = useSelector(
		(state: any) => state.viewEngagementReducer
	);

	const pollRef = useRef<any>();

	const [isActivated, setIsActivated] = useState<boolean>(mediaDetails?.custom?.activatedViewerEngagement);
	const [shouldUpdateSettings, setShouldUpdateSettings] = useState<boolean>(
		mediaDetails?.custom?.activatedViewerEngagement
	);

	const { settings } = useFetchSettings({
		defaultAccountId,
		mediaId,
		shouldUpdateSettings,
		callback: () => setShouldUpdateSettings(false),
	});

	const onTabChange = useCallback((_: React.ChangeEvent<{}>, value: string) => {
		setSelectedTab(value);
	}, []);

	const onAccountBtnClick = useCallback(() => {
		dispatch(setOpenChatSettings(!openChatSettings));
	}, [dispatch, openChatSettings]);

	const createNewPoll = useCallback(
		(pollData: PollsContainerTemplate.PollData, shouldPublish = false) => {
			const { text, options } = pollData;
			if (!text || options.filter((o) => o.text).length < 2) {
				return;
			}

			const pollId = pollData.id;
			const pollBody = {
				id: pollId,
				sender: {
					userId: username,
					name:
						settings && settings.adminProfile && settings.adminProfile.chatName
							? settings.adminProfile.chatName
							: 'admin',
					admin: true,
					email: username,
				},
				text: text,
				options: options,
				selections: 1,
			};

			dispatch(setViewEngagementLoading(true));

			dispatch(createPoll(defaultAccountId, mediaId, pollBody))
				.then((res: any) => {
					if (res && res.message) {
						dispatch(
							showMessage(
								`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CREATE_FAILED_MESSAGE')}. ${
									res.message
								}`,
								messageTypes.error
							)
						);
						dispatch(setViewEngagementLoading(false));
						return;
					}

					if (shouldPublish) {
						if (activePoll) {
							dispatch(unpublishPoll(defaultAccountId, mediaId, activePoll.id))
								.then(() => {
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH_SUCCESS_MESSAGE'
											)}`,
											messageTypes.info
										)
									);
								})
								.catch((error: any) =>
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_UN_PUBLISH_FAILED_MESSAGE'
											)}. ${error}`,
											messageTypes.error
										)
									)
								);
						}

						dispatch(publishPoll(defaultAccountId, mediaId, pollId))
							.then((publishRes: any) => {
								if (publishRes && publishRes.message) {
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_FAILED_MESSAGE'
											)}. ${publishRes.message}`,
											messageTypes.error
										)
									);
									dispatch(setViewEngagementLoading(false));
									pollRef.current.updatePolls();
									return;
								}

								dispatch(
									showMessage(
										`${t(
											'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CREATED_AND_PUBLISH'
										)}`,
										messageTypes.success
									)
								);
								dispatch(setViewEngagementLoading(false));
								pollRef.current.updatePolls();
							})
							.catch((error: any) => {
								dispatch(
									showMessage(
										`${t(
											'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_PUBLISH_FAILED_MESSAGE'
										)}. ${error}`,
										messageTypes.error
									)
								);
								dispatch(setViewEngagementLoading(false));
								pollRef.current.updatePolls();
							});
					} else {
						dispatch(
							showMessage(
								`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_QUEUED')} `,
								messageTypes.success
							)
						);
						pollRef.current.updatePolls();
						dispatch(setViewEngagementLoading(false));
					}
				})
				.catch((error: any) => {
					dispatch(
						showMessage(
							`${t(
								'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_CREATE_FAILED_MESSAGE'
							)}. ${error}`,
							messageTypes.error
						)
					);
					dispatch(setViewEngagementLoading(false));
				});
		},
		[activePoll, defaultAccountId, dispatch, mediaId, settings, t, username]
	);

	const onActivateViewerEngagement = useCallback(() => {
		dispatch(setViewEngagementLoading(true));

		dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias)).then((media: any) => {
			let mediaDetail = getDetails(media, false);
			if (!mediaDetail) {
				return;
			}

			if (mediaDetail.hasOwnProperty('custom') && mediaDetail.custom) {
				mediaDetail.custom = {
					...mediaDetail.custom,
					activatedViewerEngagement: true,
				};
			} else {
				mediaDetail = {
					...mediaDetail,
					custom: { activatedViewerEngagement: true },
				};
			}

			const body = {
				id: mediaDetail.id,
				asset: {
					id: mediaDetail.asset.id,
				},
				catalog: {},
				metadata: mediaDetail.metadata,
				tags: mediaDetail.tags,
				custom: mediaDetail.custom,
			};

			dispatch(saveInspectScreenDetails(defaultAccountId, mediaId, mediaNames.medias, body)).then((data: any) => {
				if (!data) {
					dispatch(
						showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_CANT_ACTIVATED_MESSAGE'),
							messageTypes.error
						)
					);
					dispatch(setViewEngagementLoading(false));
					return;
				}

				setIsActivated(true);
				setShouldUpdateSettings(true);
				dispatch(
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_ACTIVATED_SUCCESS_MESSAGE'),
						messageTypes.success
					)
				);
				dispatch(setViewEngagementLoading(false));
				reloadData?.();
			});
		});
	}, [defaultAccountId, dispatch, mediaId, reloadData, t]);

	return (
		<div className={`${baseClassName}`}>
			<div className={`${baseClassName}__header`}>
				<IconButton style={{ color: '#fff' }} className={`${baseClassName}__back-btn`} onClick={backToList}>
					<ArrowBack />
				</IconButton>
				<span>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEW_ENGAGEMENT')}</span>
				{isActivated && (
					<IconButton
						style={{ color: '#fff' }}
						className={`${baseClassName}__account-btn`}
						onClick={onAccountBtnClick}
					>
						<AccountCircleOutlined />
					</IconButton>
				)}
			</div>

			{!isActivated && (
				<ViewerEngagementTemplate.InactivatedViewerEngagement
					backToList={backToList}
					mediaId={mediaId}
					onActivateViewerEngagement={onActivateViewerEngagement}
				/>
			)}

			{isActivated && (
				<div className={`${baseClassName}__container ${baseClassName}__container--active`}>
					<div className={`${baseClassName}__tab-content`}>
						{selectedTab === ViewerEngagementTemplate.TAB.chat && (
							<ChatContainer mediaId={mediaId} settings={settings} />
						)}
						{selectedTab === ViewerEngagementTemplate.TAB.qna && (
							<QuestionAndAnswerContainer mediaId={mediaId} settings={settings} />
						)}
						{selectedTab === ViewerEngagementTemplate.TAB.polls && (
							<PollsContainer mediaId={mediaId} ref={pollRef} />
						)}
						{selectedTab === ViewerEngagementTemplate.TAB.settings && (
							<ViewerEngagementSettings
								mediaId={mediaId}
								mediaDetails={mediaDetails}
								reloadData={reloadData}
							/>
						)}
						{openChatSettings && (
							<ViewerEngagementAdminProfileSettings
								mediaId={mediaId}
								settings={settings}
								onSaveSettings={() => setShouldUpdateSettings(true)}
							/>
						)}

						{showCreatePollForm && <PollsContainerTemplate.CreatePollForm createNewPoll={createNewPoll} />}
					</div>

					<Tabs
						value={selectedTab}
						onChange={onTabChange}
						orientation="vertical"
						className={`${baseClassName}__tab-bar ${ViewerEngagementTemplate.baseClassName}__tab-bar--vertical`}
						TabIndicatorProps={{
							style: {
								color: '#ffffff',
								backgroundColor: 'transparent',
							},
						}}
					>
						{ViewerEngagementTemplate.TabItems.map((item) => (
							<Tab
								key={item.value}
								value={item.value}
								label={t(item.label)}
								icon={item.icon}
								className={joinClassNames([
									`${baseClassName}__tab`,
									item.value === ViewerEngagementTemplate.TAB.polls
										? `${baseClassName}__tab-polls`
										: '',
									item.value === selectedTab ? `${baseClassName}__tab--selected` : '',
								])}
							/>
						))}
					</Tabs>
				</div>
			)}

			{loading && (
				<LoadingOverlay
					className={`${baseClassName}__loading`}
					active
					spinner={<ScaleLoader color={'#ffffff'} />}
				/>
			)}

			<ViewerEngagementTemplate.InformationDialog
				open={openInformationDialog}
				title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_CHAT_ADMIN_MODAL_TITLE')}
				textContent={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_CHAT_ADMIN_MODAL_CONTENT')}
				actionText={t(
					'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_CHAT_ADMIN_MODAL_ACTION_LABEL'
				)}
				onAccept={() => {
					dispatch(setOpenChatSettings(true));
					dispatch(setOpenInformationDialog(false));
				}}
				onClose={() => dispatch(setOpenInformationDialog(false))}
			/>
		</div>
	);
};

export default ViewerEngagement;
